:root {
  --navbar-height: 16px;
  --navbar-margin: -16px;
  --keyboard-width: 320px;
  --keyboard-key-fontsize: 40px;
  --keyboard-input-fontsize: 24px;
}

.dishop-iso .text-color {
  color: black !important;
}

* {
  touch-action: manipulation;
}

.dishop-iso .text-color-secondary {
  color: #6e747c !important;
}

.dishop-iso .text-description {
  color: gray;
}

.dishop-iso .text-title {
  font-family: 'Anton', sans-serif;
}

.dishop-iso .text-product {
  width: 100% !important;
}

.dishop-iso .text-danger {
  color: red;
  margin-bottom: 1rem;
}

.dishop-iso .header-container-option {
  font-size: 24px;
}
.dishop-iso .header-description-container-option {
  font-size: 16px;
}

.dishop-iso .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.dishop-iso .text-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.dishop-iso .zoom:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.modal-size-fixed {
  overflow: auto;
  height: fit-content;
  max-height: 90vh;
  border-radius: 32px;
}

.dishop-iso .modal-sticky-footer {
  position: sticky;
  bottom: 0px;
  background-color: white;
  z-index: 1000;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.dishop-iso .modal-sticky-header {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 1000;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.modal-terminal-vertical {
  max-width: 90%;
  max-height: 90%;
  margin: 1.75rem auto;
}

.dishop-iso .carousel-control-terminal-vertical-next-icon,
.dishop-iso .carousel-control-terminal-vertical-prev-icon {
  width: 50px;
  height: 50px;
}

.dishop-iso .image-option {
  width: 220px !important;
  height: 220px !important;
}

.dishop-iso .overlay-cover {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  cursor: pointer;
}

.dishop-iso #sticky-footer a {
  color: #222222;
}

.dishop-iso .scroll-menu-arrow {
  padding: 10px;
  cursor: pointer;
}

.dishop-iso .scroll-menu-arrow--disabled {
  visibility: hidden;
}

.dishop-iso .scroll-left {
  direction: rtl;
}

.dishop-iso .custom-border-bottom {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: black;
}

.dishop-iso .custom-border-bottom-active {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: white;
}

.dishop-iso .custom-border-bottom-none {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: transparent;
}

.dishop-iso .custom-link:hover {
  color: #00a0c6;
  text-decoration: none;
  cursor: pointer;
}

.dishop-iso .productCard:hover {
  border: 0.1mm solid #3f3f3f;
}

.dishop-iso .button-bottom-modal:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.dishop-iso #sticky-cart {
  border: 0.1mm solid #dddddd;
}

.modal-content {
  border: 0px solid #dddddd !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.dishop-iso .image-modal-product {
  max-height: 40vh !important;
  object-fit: contain;
}

.dishop-iso .image-product-grid-vertical {
  height: 12vw !important;
  object-fit: contain;
}

.dishop-iso .image-product-list-horizontal {
  height: 10vw !important;
  object-fit: contain;
}

.dishop-iso .image-product-list-vertical {
  height: 12vw !important;
  object-fit: contain;
}

.dishop-iso .cover {
  height: 36vw;
}

.dishop-iso .cover-title {
  font-size: 3vw;
  font-weight: 500;
}

.dishop-iso .cover-description {
  font-size: 1.5vw;
  font-weight: 500;
}
.dishop-iso .carousel {
  height: 100%;
}
.dishop-iso .carousel-inner {
  height: 100%;
}
.dishop-iso .carousel-item {
  height: 100%;
}
.dishop-iso .image-shop-list {
  height: 12vw !important;
  object-fit: cover;
}

.dishop-iso .shop-list {
  height: 20vw !important;
}

.dishop-iso .text-shop {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.2;
}

.dishop-iso .text-shop-description {
  color: gray;
  font-weight: 300;
  flex: 1;
  font-size: 0.9em;
}

@media (max-width: 400px) {
  .dishop-iso .text-shop {
    font-size: 1.1em;
    font-weight: 700;
    line-height: 1.2;
  }
  .dishop-iso .shop-list {
    height: 80vw !important;
  }
  .dishop-iso .image-shop-list {
    height: 40vw !important;
    object-fit: cover;
  }
}

@media (min-width: 400px) and (max-width: 576px) {
  .dishop-iso .shop-list {
    height: 65vw !important;
  }
  .dishop-iso .image-shop-list {
    height: 32vw !important;
    object-fit: cover;
  }
}

@media (max-width: 575px) {
  .dishop-iso .text-description {
    font-size: 3vw;
    color: gray;
  }
  .dishop-iso .text-product {
    font-size: 4.5vw;
  }
  .dishop-iso .image-product-grid-horizontal {
    height: 34vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-list-horizontal {
    height: 40vw !important;
    object-fit: contain;
  }
  .dishop-iso .text-order-type {
    font-size: 20px;
  }
  .dishop-iso .cover {
    height: 320px;
  }
  .dishop-iso .cover-title {
    font-size: 32px;
    font-weight: 500;
  }
  .dishop-iso .cover-description {
    font-size: 16px;
    font-weight: 500;
  }

  .dishop-iso .image-modal-product {
    max-height: 35vh !important;
    object-fit: contain;
  }

  .modal-size-fixed {
    max-height: 80vh;
  }

  .dishop-iso .header-container-option {
    font-size: 20px;
  }
  .dishop-iso .header-description-container-option {
    font-size: 14px;
  }
  .dishop-iso .text-option-price-size {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .dishop-iso .text-description {
    font-size: 2vw;
    color: gray;
  }
  .dishop-iso .text-product {
    font-size: 2.5vw;
  }
  .dishop-iso .image-product-grid-horizontal {
    height: 30vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-grid-vertical {
    height: 22vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-list-vertical {
    height: 20vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-list-horizontal {
    height: 24vw !important;
    object-fit: contain;
  }
  .dishop-iso .cover {
    height: 400px;
  }
  .dishop-iso .cover-title {
    font-size: 40px;
    font-weight: 500;
  }
  .dishop-iso .cover-description {
    font-size: 20px;
    font-weight: 500;
  }

  .dishop-iso .image-shop-list {
    height: 20vw !important;
    object-fit: cover;
  }
  .dishop-iso .shop-list {
    height: 38vw !important;
  }
  .dishop-iso .text-shop-description {
    font-size: 1em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .dishop-iso .text-description {
    color: gray;
    font-size: 1.25vw;
  }
  .dishop-iso .text-product {
    font-size: 1.75vw;
  }
  .dishop-iso .image-product-grid-vertical {
    height: 16vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-list-horizontal {
    height: 14vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-list-vertical {
    height: 14vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-grid-horizontal {
    height: 26vh !important;
    object-fit: contain;
  }
  .dishop-iso .cover {
    height: 480px;
  }
  .dishop-iso .cover-title {
    font-size: 48px;
    font-weight: 500;
  }
  .dishop-iso .cover-description {
    font-size: 24px;
    font-weight: 500;
  }
  .dishop-iso .image-shop-list {
    height: 15vw !important;
    object-fit: cover;
  }
  .dishop-iso .shop-list {
    height: 30vw !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .dishop-iso .text-description {
    color: gray;
    font-size: 1vw;
  }
  .dishop-iso .text-product {
    font-size: 1.5vw;
  }
  .dishop-iso .image-product-list-horizontal {
    height: 12vw !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-grid-horizontal {
    height: 26vh !important;
    object-fit: contain;
  }
  .dishop-iso .cover {
    height: 480px;
  }
  .dishop-iso .cover-title {
    font-size: 48px;
    font-weight: 500;
  }
  .dishop-iso .cover-description {
    font-size: 24px;
    font-weight: 500;
  }
  .dishop-iso .image-shop-list {
    height: 14vw !important;
    object-fit: cover;
  }
  .dishop-iso .shop-list {
    height: 27vw !important;
  }
}

@media (min-width: 1200px) {
  .dishop-iso .text-description {
    color: gray;
    font-size: 1vw;
  }
  .dishop-iso .text-product {
    font-size: 1.25vw;
  }
  .dishop-iso .image-product-grid-horizontal {
    height: 24vh !important;
    object-fit: contain;
  }
  .dishop-iso .cover {
    height: 480px;
  }
  .dishop-iso .cover-title {
    font-size: 48px;
    font-weight: 500;
  }
  .dishop-iso .cover-description {
    font-size: 24px;
    font-weight: 500;
  }
  .dishop-iso .image-shop-list {
    height: 10vw !important;
    object-fit: cover;
  }
  .dishop-iso .shop-list {
    height: 19vw !important;
  }
}

@media (min-width: 2000px) {
  .dishop-iso .image-shop-list {
    height: 9vw !important;
    object-fit: cover;
  }
  .dishop-iso .shop-list {
    height: 17vw !important;
  }
}

@media (min-height: 1800px) {
  .dishop-iso .text-description {
    color: gray;
    font-size: 9px;
  }
  .dishop-iso .image-modal-product {
    height: 20vh !important;
    object-fit: contain;
  }
  .dishop-iso .image-product-grid-horizontal {
    height: 150px !important;
    object-fit: contain;
  }
  .dishop-iso .cover {
    height: 280px;
  }
  .dishop-iso .cover-title {
    font-size: 48px;
    font-weight: 500;
  }
  .dishop-iso .cover-description {
    font-size: 32px;
    font-weight: 500;
  }
  .dishop-iso .button-terminal-vertical-size {
    width: 260px !important;
    height: 260px !important;
  }
  .dishop-iso .image-option {
    width: 220px !important;
    height: 220px !important;
  }
}

.target-section:target:before {
  content: '';
  display: block;
  height: var(--navbar-height); /* fixed header height */
  margin: var(--navbar-margin) 0 0; /* negative fixed header height */
}

.dishop-iso .no-outline:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.ps__rail-y {
  display: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dishop-iso .line-break {
  white-space: pre-line;
}

.dishop-iso .hr-gray {
  border-top: 1px solid #d9e2ef;
}

.dishop-iso .zoom {
  transition: transform 0.3s;
}

.dishop-iso .zoom:hover {
  transform: scale(1.1);
}

.dishop-iso .info-bar {
  color: white;
  background-color: #3f3f3f;
}

.dishop-iso .section-bar {
  color: black;
  background-color: white;
}

.dishop-iso .border-bottom-mid-gray {
  border-bottom: 1px solid #b5b8bb !important;
}

.dishop-iso .border-color {
  border: 1px solid #d9e2ef;
}

.ui-keyboard {
  margin-top: 1rem !important;
  font-size: var(--keyboard-key-fontsize) !important;
  position: inherit !important;
  float: inherit !important;
  margin: 1rem auto !important;
  width: var(--keyboard-width) !important;
}

.ui-keyboard-button {
  padding: 1rem 2.25rem !important;
  font-size: var(--keyboard-input-fontsize) !important;
}

.ui-keyboard-input {
  font-size: var(--keyboard-input-fontsize) !important;
  width: var(--keyboard-width) !important;
  margin: auto !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.dishop-iso .datepicker-input {
  border: 1px solid #ced4da !important;
  color: #495057;
}

.dishop-iso .invalid-feedback-block {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.dishop-iso .hrStyle {
  border-bottom: 0.5px solid #c5f3ec !important;
  width: 100%;
}
.dishop-iso .bottom-navigation-style {
  background: #f7f5f5;
  border-radius: 32px;
  width: -webkit-fill-available;
  height: fit-content;
}
.dishop-iso .MuiBottomNavigationAction-wrapper {
  width: 100%;
  display: block;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dishop-iso .MuiBottomNavigationAction-label {
  opacity: 1;
  font-size: 12px;
  transition: font-size 0.2s, opacity 0.2s;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  transition-delay: 0.1s;
}
.dishop-iso .MuiBottomNavigationAction-label.Mui-selected {
  font-size: 12px;
}
.dishop-iso .labelStyle {
  font-size: 12px;
  padding: 0px 5px;
  font-weight: bold;
  font-family: sans-serif;
}
.dishop-iso .title {
  padding-bottom: 24px;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -1px;
}
.dishop-iso .title2 {
  font-size: 24px;
  margin-top: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
}
.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
.dishop-iso .closeStyle {
  z-index: 10;
  position: absolute;
}
.MuiInputAdornment-root {
  display: flex;
  margin-top: -11px;
  color: #cdc8c8;
  margin-left: 2px;
}
.dishop-iso .inputStyle {
  position: relative;
  font-size: 16px;
  border: 1px solid;
  border-radius: 32px !important;
}
.inputStyle input {
  text-indent: 32px;
}

.inputStyle .fa-map-marker {
  position: absolute;
  top: 8px;
  left: 12px;
}
.inputStyle .fa-check {
  position: absolute;
  top: 7px;
  right: 14px;
  font-size: large;
}
.menu {
  position: relative !important;
  list-style-type: none;
  overflow: auto;
  max-height: 102px;
  border: 1px solid grey;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.menu > li:hover {
  color: white !important;
}
.menu > li :hover {
  color: white !important;
}
.lightcolor {
  color: #c1b6b6;
}
.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}
.dishop-iso .borderMenu {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}
.dishop-iso .menuStyle {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
  border-top: 1px solid !important;
  border-bottom: 0px;
}
.border-red {
  border: 1px solid red !important;
}
.animationOpen {
  animation: opened 500ms forwards;
}
@keyframes opened {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 102px;
  }
}
.animationClose {
  animation: closed 500ms forwards;
  opacity:0;
  border-style: none;
}
@keyframes closed {
  0% {
  max-height: 102px;
  }
  100% {
  max-height: 0px;
  }
}
.padding {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}